import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from '../../components/layout/layout';
import { PageHeading } from '../../components/layout/page-heading';
import { PageSubHeading } from '../../components/layout/page-subheading';
import { EventCard } from '../../components/cards/event-card';
import { YearTabs } from '../../components/years-tab';
import { PageDescription } from '../../components/layout/page-description';

const EventCards = ({ events = [] }) => {
  const filteredEvents = events
    .filter((event) => event.slug !== 'dummy-content-do-not-delete')
    .sort((a, b) => new Date(b.rawDate) - new Date(a.rawDate));

  if (filteredEvents.length === 0) {
    return (
      <p className="text-center py-8 text-lg font-semibold text-gray-500">
        No events yet. Stay tuned!
      </p>
    );
  }

  return (
    <div className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {filteredEvents.map((event) => (
        <Link
          to={event.slug}
          key={event.id}
          className="group col-span-2 sm:col-span-1"
        >
          <EventCard
            image={event.portraitPhoto.fixed.src}
            title={event.title}
            author={event.author}
            date={event.submissionDate}
          />
        </Link>
      ))}
    </div>
  );
};

const Events = ({ data, location }) => {
  const eventsPage = data.contentfulEventsPage;
  const currentYearRange = eventsPage.currentYear.yearRange;

  const eventYears = [eventsPage.currentYear, ...eventsPage.previousYears];
  const eventYearsMap = eventYears.reduce((obj, item) => {
    return { ...obj, [item['yearRange']]: item };
  }, {});

  const [selectedYear, setSelectedYear] = useState(eventsPage.currentYear);

  const yearRanges = [
    currentYearRange,
    ...eventsPage.previousYears.map((prevYear) => prevYear.yearRange),
  ];

  useEffect(() => {
    if (location.hash) {
      setSelectedYear(eventYearsMap[location.hash.split('#')[1]]);
    } else {
      setSelectedYear(eventsPage.currentYear);
    }
  }, [location.hash, eventYearsMap, eventsPage.currentYear]);

  return (
    <Layout title={'Events'}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0">
        <div className="max-w-2xl mx-auto py-8 lg:max-w-none">
          <PageHeading title={eventsPage.pageTitle} />
          <PageDescription description={eventsPage.description.description} />

          <YearTabs
            selectedYearRange={selectedYear.yearRange}
            yearRanges={yearRanges}
          />

          {selectedYear.yearRange === currentYearRange && (
            <>
              <div className="mb-8">
                <PageSubHeading title={'Upcoming Events'} />
                <EventCards events={selectedYear.upcomingEvents ?? []} />
              </div>

              <PageSubHeading title={'Past Events'} />
            </>
          )}

          <EventCards events={selectedYear.pastEvents ?? []} />
        </div>
      </div>
    </Layout>
  );
};

export default Events;

export const query = graphql`
  query {
    contentfulEventsPage {
      pageTitle
      description {
        description
      }
      currentYear {
        yearRange
        pastEvents {
          ...EventInformation
        }
        upcomingEvents {
          ...EventInformation
        }
      }
      previousYears {
        yearRange
        pastEvents {
          ...EventInformation
        }
        upcomingEvents {
          ...EventInformation
        }
      }
    }
  }
  fragment EventInformation on ContentfulEvent {
    id
    title
    slug
    submissionDate(formatString: "Do MMMM, YYYY")
    portraitPhoto {
      fixed(width: 600) {
        width
        height
        src
        srcSet
      }
    }
    author {
      name
      description {
        description
      }
      displayPicture {
        fixed(width: 400) {
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
