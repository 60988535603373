import React from 'react';
import { AuthorCardFooter } from './author/author-card-footer';

export const EventCard = ({ image, title, author, date }) => {
  return (
    <div
      className={`flex flex-col h-full rounded-lg shadow-md transform transition duration-200 md:hover:shadow-xl`}
    >
      <img
        className="w-full h-96 rounded-t-lg object-cover object-center overflow-hidden"
        alt={`${title}`}
        src={image}
      />
      <div
        className={`flex flex-col flex-grow p-4 bg-primaryLight rounded-b-lg gap-4`}
      >
        <h2 className="text-gray-800 font-heading text-2xl font-semibold">
          {title}
        </h2>
        <AuthorCardFooter author={author} date={date} />
      </div>
    </div>
  );
};
